import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
    {
        id: "home",
        title: "Home",
        type: "item",
        icon: <Icon.Home size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },
    {
        id: "Meeting",
        title: "Meeting",
        type: "item",
        icon: <Icon.Calendar size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vlcMeetings"
    },
    {
        id: "Room",
        title: "Room",
        type: "item",
        icon: <Icon.Users size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/vlcRoom"
    },
    {
        id: "Library",
        title: "Library",
        type: "item",
        icon: <Icon.BookOpen size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/library"
    },
    {
        id: "Recording",
        title: "Recording",
        type: "item",
        icon: <Icon.Video size={20} className='my-video-icon'/>,
        permissions: ["admin", "editor"],
        navLink: "/VlcRecording"
    },
    {
        id: "AccountSettings",
        title: "AccountSettings",
        type: "item",
        icon: <Icon.Settings size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/account"
    },
    {
        id: "MySubscription",
        title: "MySubscription",
        type: "item",
        icon: <Icon.Paperclip size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/my-subscription"
    },
    {
        id: "Invoice",
        title: "Invoice",
        type: "item",
        icon: <Icon.DollarSign size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/InvoicePage"
    }
]

export default navigationConfig
